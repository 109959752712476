var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "store-detail" }, [
    _c(
      "div",
      { staticClass: "card" },
      [
        _c(
          "topOperatingButton",
          {
            attrs: {
              disabled:
                _vm.disabled &&
                (_vm.disabledBtn === undefined || _vm.disabledBtn === true),
              showAudit: _vm.showAudit,
              isAddBtn: _vm.showAdd,
              isExamineBtn: ["130101", "140309", "170309", "170409"].includes(
                _vm.billType
              )
                ? true
                : false,
              isAuditBillBtn: ["130101", "140309", "170309", "170409"].includes(
                _vm.billType
              )
                ? false
                : true,
              id: "topOperatingButton",
            },
            on: {
              submitForm: _vm.handleSubmitStart,
              addBill: _vm.AddBill,
              auditBill: _vm.changeBillStatus,
              handleAdd: _vm.handleAdd,
              getQuit: _vm.getQuit,
            },
          },
          [
            _c(
              "template",
              { slot: "specialDiyBtn" },
              [
                _vm.billType === "130105" || _vm.billType === "130106"
                  ? _c(
                      "el-button",
                      {
                        attrs: { size: "mini", disabled: _vm.disabled },
                        on: { click: _vm.storeSelectBills },
                      },
                      [_vm._v("引入源单 ")]
                    )
                  : _vm._e(),
                _c(
                  "el-button",
                  {
                    attrs: {
                      icon: "el-icon-remove-outline",
                      size: "mini",
                      disabled: _vm.single,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.changeBillStatus("关闭")
                      },
                    },
                  },
                  [_vm._v("终止单据 ")]
                ),
              ],
              1
            ),
          ],
          2
        ),
        _c(
          "el-form",
          {
            ref: "form",
            staticClass: "ken-detail-form",
            attrs: {
              model: _vm.form,
              "label-width": "100px",
              "label-position": "right",
              rules: _vm.rules,
              size: "mini",
            },
          },
          [
            _c(
              "cardTitleCom",
              {
                attrs: {
                  cardTitle: "基本信息",
                  billStatus: _vm.form.billStatus,
                  id: "basicInformation",
                },
              },
              [
                _c("template", { slot: "cardContent" }, [
                  _c(
                    "div",
                    { staticClass: "x-f marT10" },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "单据编号", prop: "billNo" } },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "单据编号",
                              disabled: "",
                              size: "mini",
                            },
                            model: {
                              value: _vm.form.billNo,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "billNo", $$v)
                              },
                              expression: "form.billNo",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "单据日期", prop: "billDate" } },
                        [
                          _c("el-date-picker", {
                            attrs: {
                              type: "date",
                              placeholder: "选择日期",
                              disabled: _vm.disabled,
                              "value-format": "yyyy-MM-dd",
                              size: "mini",
                            },
                            model: {
                              value: _vm.form.billDate,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "billDate", $$v)
                              },
                              expression: "form.billDate",
                            },
                          }),
                        ],
                        1
                      ),
                      ["130105", "130106"].includes(_vm.billType)
                        ? _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "业务类型",
                                prop: "billCustomType",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "250px" },
                                  attrs: {
                                    size: "mini",
                                    disabled:
                                      _vm.billType == 130106
                                        ? true
                                        : _vm.disabled,
                                    placeholder: "请选择业务类型",
                                  },
                                  model: {
                                    value: _vm.form.billCustomType,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "billCustomType", $$v)
                                    },
                                    expression: "form.billCustomType",
                                  },
                                },
                                [
                                  _c("el-option", {
                                    attrs: { label: "同价调拨", value: 1 },
                                  }),
                                  _c("el-option", {
                                    attrs: { label: "异价调拨", value: 2 },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._t("selectOneArea", null, {
                        form: _vm.form,
                        disabled: _vm.disabled,
                        storeData: _vm.storeData,
                        getStore: _vm.getStore,
                        deptData: _vm.deptData,
                        normalizerStore: _vm.normalizerStore,
                        getDiyselectId: _vm.getDiyselectId,
                      }),
                    ],
                    2
                  ),
                  _c(
                    "div",
                    { staticClass: "x-f" },
                    [
                      _vm._t("selectTwoArea", null, {
                        form: _vm.form,
                        disabled: _vm.disabled,
                      }),
                    ],
                    2
                  ),
                ]),
              ],
              2
            ),
            _c(
              "cardTitleCom",
              { attrs: { cardTitle: "商品信息" } },
              [
                _c("template", { slot: "rightCardTitle" }, [
                  _c(
                    "div",
                    { staticClass: "marR10" },
                    [
                      ["130102", "130103"].includes(_vm.billType)
                        ? _c(
                            "el-button",
                            {
                              attrs: {
                                type: "primary",
                                disabled: _vm.disabled,
                                size: "mini",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.getDistribution(0)
                                },
                              },
                            },
                            [_vm._v("批量修改仓库")]
                          )
                        : _vm._e(),
                      ["130104"].includes(_vm.billType)
                        ? _c(
                            "el-button",
                            {
                              attrs: {
                                type: "primary",
                                disabled: _vm.disabled,
                                size: "mini",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.getDistribution(2)
                                },
                              },
                            },
                            [_vm._v("批量修改调入仓库")]
                          )
                        : _vm._e(),
                      ["130104"].includes(_vm.billType)
                        ? _c(
                            "el-button",
                            {
                              attrs: {
                                type: "primary",
                                disabled: _vm.disabled,
                                size: "mini",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.getDistribution(1)
                                },
                              },
                            },
                            [_vm._v("批量修改调出仓库")]
                          )
                        : _vm._e(),
                      _c(
                        "el-button",
                        {
                          staticClass: "marL10",
                          attrs: {
                            type: "primary",
                            size: "mini",
                            disabled: _vm.form.billStatus != "0",
                          },
                          on: { click: _vm.handleOpenImport },
                        },
                        [_vm._v("导入")]
                      ),
                    ],
                    1
                  ),
                ]),
                _c("template", { slot: "cardContent" }, [
                  _c(
                    "div",
                    { staticClass: "store-table" },
                    [
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c("keyBoardTable", {
                                ref: "myTable",
                                attrs: {
                                  isDisabled: _vm.disabled,
                                  isShowCheckbox: _vm.isShowCheckbox,
                                  isShowTableIndex: _vm.isShowTableIndex,
                                  tableHeight: "450px",
                                  uuid: _vm.uuid,
                                  isTableContentDisabled:
                                    _vm.isTableContentDisabled,
                                  isCreatedInit: _vm.isCreatedInit,
                                  tableColumns: _vm.originColumns,
                                  tableColumnsCopy: _vm.originColumnsCopy,
                                },
                                on: {
                                  handleSelectionChange:
                                    _vm.handleSelectionChange,
                                  handleTableInput: _vm.handleTableInput,
                                  handleTableBlur: _vm.handleTableBlur,
                                  handleSelectLocalBlur:
                                    _vm.handleSelectLocalBlur,
                                  handleSelectLocalChange:
                                    _vm.handleSelectLocalChange,
                                  handleTableSelectRemoteChange:
                                    _vm.handleTableSelectRemoteChange,
                                  handleSyncTableData: _vm.handleSyncTableData,
                                  handleTableCallback: _vm.handleTableCallback,
                                  footerMethod: _vm.footerMethod,
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
              ],
              2
            ),
            _c(
              "div",
              { staticClass: "store-footer" },
              [
                _c("accessoryUpload", {
                  attrs: {
                    disabled:
                      _vm.disabled &&
                      (_vm.disabledBtn === undefined ||
                        _vm.disabledBtn === true),
                    fileList: _vm.form.fileItems,
                  },
                  on: {
                    getFileItems: _vm.getFileItemsData,
                    delFileItems: _vm.delFileItems,
                  },
                }),
                _c(
                  "div",
                  { staticClass: "right-remark" },
                  [
                    _c("span", [_vm._v("单据备注:")]),
                    _c("el-input", {
                      staticClass: "input",
                      attrs: {
                        placeholder: "备注",
                        disabled:
                          _vm.disabled &&
                          (_vm.disabledRemark === undefined ||
                            _vm.disabledRemark === true),
                        type: "textarea",
                        rows: 3,
                      },
                      model: {
                        value: _vm.form.billRemark,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "billRemark", $$v)
                        },
                        expression: "form.billRemark",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c("SelectGoods", {
          attrs: {
            OpenGoods: _vm.OpenGoods,
            queryCode: _vm.queryCode,
            isStore: _vm.isNewFetchGoods,
            extraParams: _vm.selectGoodsExtraParams,
          },
          on: {
            "update:OpenGoods": function ($event) {
              _vm.OpenGoods = $event
            },
            "update:open-goods": function ($event) {
              _vm.OpenGoods = $event
            },
            getGoodsIds: _vm.getGoods,
          },
        }),
        _c("SelectBills", {
          attrs: {
            title: _vm.title,
            NoFields: _vm.NoFields,
            tableColumnFields: _vm.tableColumnFields,
            billList: _vm.billList,
            openDialog: _vm.openBillDia,
            loadingDia: _vm.loadingDia,
            billTotal: _vm.billTotal,
            allBillList: _vm.allBillList,
            billType: _vm.billType,
          },
          on: {
            "update:openDialog": function ($event) {
              _vm.openBillDia = $event
            },
            "update:open-dialog": function ($event) {
              _vm.openBillDia = $event
            },
            getSelect: _vm.storeSelectBills,
            getAllBills: _vm.getSelectBills,
            getBillsIds: function ($event) {
              return _vm.getSelectIds($event, true)
            },
            search: function (searchObj) {
              return _vm.storeSelectBills(searchObj)
            },
          },
        }),
        _c("Dialog", {
          attrs: { options: _vm.dialogOptions },
          on: {
            "update:options": function ($event) {
              _vm.dialogOptions = $event
            },
            handleEvent: _vm.handleEvent,
          },
        }),
        _c("to-channel", {
          attrs: { visible: _vm.openToChannel, downloadFile: _vm.downloadFile },
          on: {
            "update:visible": function ($event) {
              _vm.openToChannel = $event
            },
            getFileUrl: _vm.getFileUrl,
            upload: _vm.uploadFile,
          },
        }),
        _c("importError", {
          attrs: { errVisible: _vm.errVisible, errorList: _vm.errorList },
          on: {
            "update:errVisible": function ($event) {
              _vm.errVisible = $event
            },
            "update:err-visible": function ($event) {
              _vm.errVisible = $event
            },
          },
        }),
      ],
      1
    ),
    ["130101", "140309", "170309", "170409"].includes(_vm.billType)
      ? _c("div", { staticClass: "footer" }, [
          _c("div", { staticClass: "left" }),
          _c("div", { staticClass: "right" }, [
            _c("div", { staticClass: "item" }, [
              _c("div", { staticClass: "label" }, [_vm._v("单据金额 :")]),
              _c("div", { staticClass: "value" }, [
                _vm._v(_vm._s(_vm.billMoney)),
              ]),
            ]),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }